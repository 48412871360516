import * as React from 'react';
import { Box } from 'grommet';
import FlowDetailTitle from './flowDetailTitle';

const FlowDetailStep4 = () => {
  return (
    <Box style={{ marginBottom: '1.5rem' }}>
      <Box border="all">
        <FlowDetailTitle step="step4" label="ドラレコの設置" />
        <Box pad="medium">
          <Box>
            <ol style={{ marginBottom: '0.2rem' }}>
              <li type="1" value="4">
                {'ドラレコを発注された最寄りのトヨタL&F販売店に取付作業をご依頼ください。'}
              </li>
              <li type="1">
                {'ドラレコ取付作業までに次の点をご確認ください。（Wi-Fiの場合のみ）'}
              </li>
              <Box fill="horizontal">
                <span>{'Wi-Fi接続に必要な情報'}</span>
                <table
                  border="2"
                  rules="all"
                  style={{ borderColor: '#dddddd' }}
                  className="tbl-fix"
                >
                  <tr>
                    <th
                      style={{
                        width: '65%',
                      }}
                    >
                      <Box justify="center" align="center">
                        ご確認いただく内容
                      </Box>
                    </th>
                    <th
                      style={{
                        width: '35%',
                      }}
                    >
                      <Box justify="center" align="center">
                        特記事項
                      </Box>
                    </th>
                  </tr>
                  <tr className="tr-left">
                    <td>
                      <Box>
                        <ul style={{ marginBottom: '0rem' }}>
                          <li>Wi-Fiアクセスポイントとそのパスワード </li>
                        </ul>
                      </Box>
                    </td>
                    <td>
                      <Box>
                        <ul style={{ marginBottom: '0rem' }}>
                          <li>2.4GHz帯、5.0GHz帯使用可能</li>
                          <li>ドラレコへの登録は最大50箇所 </li>
                        </ul>
                      </Box>
                    </td>
                  </tr>
                  <tr className="tr-left">
                    <td>
                      <Box>
                        <ul style={{ marginBottom: '0rem' }}>
                          <li>{'アクセスポイントの暗号方式はWPA(2)-PSKかWPA-EAP-PEAPか？'}</li>
                          <li>PEAPの場合、IDとパスワード</li>
                        </ul>
                      </Box>
                    </td>
                    <td rowSpan="3">
                      <Box>
                        <ul style={{ marginBottom: '0rem' }}>
                          <li>お客様のネットワーク管理者様にご確認いただきます</li>
                        </ul>
                      </Box>
                    </td>
                  </tr>
                  <tr className="tr-left">
                    <td>
                      <Box>
                        <ul style={{ marginBottom: '0rem' }}>
                          <li>ドラレコのIPアドレスは自動配布か固定アドレスか？</li>
                          <li>固定の場合、ドラレコに付与するIPアドレス</li>
                        </ul>
                      </Box>
                    </td>
                  </tr>
                  <tr className="tr-left">
                    <td>
                      <Box>
                        <ul style={{ marginBottom: '0rem' }}>
                          <li>プロキシは設定されているか？</li>
                          <li>設定ありの場合、プロキシIP、ポート、ドメイン、認証ID、パスワード</li>
                        </ul>
                      </Box>
                    </td>
                  </tr>
                </table>
              </Box>
            </ol>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FlowDetailStep4;
