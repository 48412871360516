import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, Image } from 'grommet';
import step2 from '../../icons/howtoApply/step2.png';
import step3 from '../../icons/howtoApply/step3.png';
import step4 from '../../icons/howtoApply/step4.png';
import step5 from '../../icons/howtoApply/step5.png';
import step6 from '../../icons/howtoApply/step6.png';
// import step7 from '../../icons/howtoApply/step7.png';

const FlowComponent = (props) => {
  let iconPath = '';
  switch (props.step) {
    case 'step2':
      iconPath = step2;
      break;
    case 'step3':
      iconPath = step3;
      break;
    case 'step4':
      iconPath = step4;
      break;
    case 'step5':
      iconPath = step5;
      break;
    case 'step6':
      iconPath = step6;
      break;
    // case 'step7':
    //   iconPath = step7;
    //   break;
    default:
      break;
  }

  return (
    <>
      <Box
        align="center"
        justify="center"
        style={{
          flexBasis: '3%',
          maxWidth: '3%',
          backgroundColor: '#ffffff',
          height: '200px',
          padding: '0 5px',
        }}
      >
        <StaticImage
          objectFit="contain"
          src="../../icons/howtoApply/nextStep.png"
          formats={['auto', 'webp', 'avif']}
          alt={'nextStep'}
          imgClassName="ofi"
        />
      </Box>
      <Box
        align="center"
        justify="center"
        style={{
          flexBasis: '12%',
          maxWidth: '12%',
          backgroundColor: '#eeeeee',
          padding: '15px 5px',
          height: '200px',
        }}
      >
        <strong style={{ marginBottom: '1rem' }}>{props.step.toUpperCase()}</strong>
        <Image
          fit="contain"
          src={iconPath}
          formats={['auto', 'webp', 'avif']}
          alt={props.step}
          height={110}
          imgClassName="ofi"
          imgStyle={{ width: '100%' }}
        />
        <Box style={{ marginTop: '0.5rem' }}>
          <strong style={{ fontSize: 'min(1vw, 1rem)' }}>{props.label}</strong>
        </Box>
      </Box>
    </>
  );
};

export default FlowComponent;
