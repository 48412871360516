import * as React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import FlowDetailTitle from './flowDetailTitle';
import { StaticImage } from 'gatsby-plugin-image';

const FlowDetailStep6 = () => {
  let appDirection = 'column';
  let displayBox = 'none';
  let displayBoxSmall = null;

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          displayBox = null;
          displayBoxSmall = 'none';
        } else {
          appDirection = 'column';
          displayBox = 'none';
          displayBoxSmall = null;
        }
        return (
          <Box style={{ marginBottom: '1.5rem' }}>
            <Box border="all">
              <FlowDetailTitle step="step6" label="FORKLORE設定" />
              <Box pad="medium">
                <ol>
                  <Box>
                    <li type="1" value="9">
                      <span>{'FORKLORE設定をクリックしてください。'}</span>
                    </li>
                    <Box direction={appDirection} justify="start" align="left">
                      <Box style={{ flexBasis: '40%' }}>
                        <li type="1">
                          <span>{'初期登録されている情報をご確認ください。'}</span>
                          <ui>
                            <span>{'必要に応じて情報の更新をしてください。'}</span>
                          </ui>
                        </li>
                      </Box>
                      <Box style={{ flexBasis: '60%' }}>
                        <Box
                          direction={appDirection}
                          justify="start"
                          align="center"
                          style={{ marginBottom: '1rem' }}
                        >
                          <Box style={{ flexBasis: '40%' }}>
                            <StaticImage
                              objectFit="contain"
                              src="../../images/howtoApply/step6-forklore-setting.png"
                              formats={['auto', 'webp', 'avif']}
                              alt={'FORKLORE設定'}
                              height={200}
                              imgClassName="ofi"
                            />
                          </Box>
                          <Box style={{ flexBasis: '20%' }}>
                            <Box style={{ display: displayBox }}>
                              <StaticImage
                                objectFit="contain"
                                src="../../icons/arrow-right.png"
                                formats={['auto', 'webp', 'avif']}
                                alt={'次'}
                                imgClassName="ofi"
                              />
                            </Box>
                            <Box style={{ display: displayBoxSmall }}>
                              <StaticImage
                                objectFit="contain"
                                src="../../icons/arrow-bottom.png"
                                formats={['auto', 'webp', 'avif']}
                                alt={'次'}
                                height={50}
                                imgClassName="ofi"
                              />
                            </Box>
                          </Box>
                          <Box style={{ flexBasis: '40%' }}>
                            <StaticImage
                              objectFit="contain"
                              src="../../images/howtoApply/step6-menu.png"
                              formats={['auto', 'webp', 'avif']}
                              alt={'FORKLORE設定 メニュー'}
                              height={150}
                              imgClassName="ofi"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box direction="column" justify="start" align="left">
                    <Box>
                      <li type="1">
                        <span>{'次の設定はサービス料金に反映されます。'}</span>
                      </li>
                    </Box>
                    <Box direction={appDirection}>
                      <Box flexBasis="60%">
                        <div justify="start" align="left">
                          <StaticImage
                            objectFit="contain"
                            src="../../images/howtoApply/step6-price.png"
                            formats={['auto', 'webp', 'avif']}
                            alt={'FORKLORE設定'}
                            imgClassName="ofi"
                          />
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </ol>
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default FlowDetailStep6;
