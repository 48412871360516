import * as React from 'react';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import Flow from '../../components/howtoApply/flow';
import FlowDetail from '../../components/howtoApply/flowDetail';

const DriverecorderConnectPage = () => (
  <Layout>
    <Seo title="お申し込みの流れ" />
    <Flow />
    <FlowDetail />
  </Layout>
);

export default DriverecorderConnectPage;
