import * as React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { StaticImage } from 'gatsby-plugin-image';
import FlowDetailTitle from './flowDetailTitle';
import FlowDetailStep2 from './flowDetailStep2';
import FlowDetailStep3 from './flowDetailStep3';
import FlowDetailStep4 from './flowDetailStep4';
import FlowDetailStep5 from './flowDetailStep5';
import FlowDetailStep6 from './flowDetailStep6';
// import FlowDetailStep7 from './flowDetailStep7';

const FlowDetail = () => {
  let pageWidth = '100%';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          pageWidth = '80%';
        } else {
          pageWidth = '100%';
        }
        return (
          <Box style={{ width: pageWidth, margin: '0 auto' }} justify="center" align="center">
            <Box fill="horizontal">
              <Box style={{ marginBottom: '1.5rem' }}>
                <Box border="all">
                  <a href="/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/">
                    <FlowDetailTitle step="step1" label="お問い合わせ" />
                  </a>
                  <Box pad="medium">
                    <ol style={{ marginBottom: '0.2rem' }}>
                      <li type="1">
                        本ホームページの
                        <a href="/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/">
                          お問い合わせページ
                        </a>
                        に、必要項目を入力、ご連絡ください。
                      </li>
                    </ol>
                    <Box>
                      <StaticImage
                        objectFit="contain"
                        src="../../images/howtoApply/step1-qa.png"
                        formats={['auto', 'webp', 'avif']}
                        alt={'お問い合わせ'}
                        imgClassName="ofi"
                        imgStyle={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <FlowDetailStep2 />
              <FlowDetailStep3 />
              <FlowDetailStep4 />
              <FlowDetailStep5 />
              <FlowDetailStep6 />
              {/* <FlowDetailStep7 /> */}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default FlowDetail;
