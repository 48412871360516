import * as React from 'react';
import { Box, Image } from 'grommet';
import step1 from '../../icons/howtoApply/step1.png';
import step2 from '../../icons/howtoApply/step2.png';
import step3 from '../../icons/howtoApply/step3.png';
import step4 from '../../icons/howtoApply/step4.png';
import step5 from '../../icons/howtoApply/step5.png';
import step6 from '../../icons/howtoApply/step6.png';
// import step7 from '../../icons/howtoApply/step7.png';

const FlowDetailTitle = (props) => {
  let iconPath = '';
  switch (props.step) {
    case 'step1':
      iconPath = step1;
      break;
    case 'step2':
      iconPath = step2;
      break;
    case 'step3':
      iconPath = step3;
      break;
    case 'step4':
      iconPath = step4;
      break;
    case 'step5':
      iconPath = step5;
      break;
    case 'step6':
      iconPath = step6;
      break;
    // case 'step7':
    //   iconPath = step7;
    //   break;
    default:
      iconPath = step1;
      break;
  }
  return (
    <Box direction="row" pad="medium" justify="start" align="center" background="#eeeeee">
      <Image
        objectFit="contain"
        src={iconPath}
        formats={['auto', 'webp', 'avif']}
        alt={props.step}
        width={30}
        style={{ marginBottom: '0rem' }}
        imgClassName="ofi"
      />
      <h3
        style={{
          fontSize: '1rem',
          marginBottom: '0rem',
          marginLeft: '10px',
        }}
      >
        {props.step.toUpperCase() + '. ' + props.label}
      </h3>
    </Box>
  );
};

export default FlowDetailTitle;
