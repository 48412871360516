import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext } from 'grommet';
import FlowComponent from './flowComponent';

const Flow = () => {
  let displayBox = 'none';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          displayBox = null;
        } else {
          displayBox = 'none';
        }
        return (
          <>
            <Box align="center" style={{ marginTop: '3rem', marginBottom: '1rem' }}>
              <Box direction="row" justify="center" align="center" style={{ marginBottom: '1rem' }}>
                <h2
                  style={{
                    fontSize: '1.3rem',
                    marginBottom: '0rem',
                  }}
                >
                  ドラレコConnect・車両動線Storyのお申込みの流れ
                </h2>
              </Box>
            </Box>
            <Box align="center" style={{ marginBottom: '3rem', display: displayBox }}>
              <Box direction="row" align="center" justify="center" style={{ marginBottom: '1rem' }}>
                <Box
                  style={{
                    flexBasis: '12%',
                    maxWidth: '12%',
                    backgroundColor: '#eeeeee',
                    padding: '15px 10px',
                    height: '200px',
                  }}
                  justify="center"
                  align="center"
                >
                  <strong style={{ marginBottom: '1rem' }}>STEP1</strong>
                  <Box
                    align="center"
                    justify="center"
                    style={{
                      backgroundColor: '#eeeeee',
                      padding: '15px 5px',
                      height: '200px',
                    }}
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../icons/howtoApply/step1.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'STEP1'}
                      height={110}
                      imgClassName="ofi"
                    />
                    <Box style={{ marginTop: '2rem' }}>
                      <a
                        href="/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/"
                        style={{ fontSize: 'min(1vw, 1rem)', color: '#000000' }}
                      >
                        <strong>お問い合わせ</strong>
                      </a>
                    </Box>
                  </Box>
                </Box>
                <FlowComponent step="step2" label="ドラレコのご注文"></FlowComponent>
                <FlowComponent step="step3" label="ご利用申し込み"></FlowComponent>
                <FlowComponent step="step4" label="ドラレコの設置"></FlowComponent>
                <FlowComponent step="step5" label="初回ログイン"></FlowComponent>
                <FlowComponent step="step6" label="FORKLORE設定"></FlowComponent>
                {/* <FlowComponent step="step7" label="FORKLORE設定"></FlowComponent> */}
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Flow;
