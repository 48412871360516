import * as React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import FlowDetailTitle from './flowDetailTitle';
import { StaticImage } from 'gatsby-plugin-image';

const FlowDetailStep5 = () => {
  let appDirection = 'column';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
        } else {
          appDirection = 'column';
        }
        return (
          <Box style={{ marginBottom: '1.5rem' }}>
            <Box border="all">
              <FlowDetailTitle step="step5" label="初回ログイン" />
              <Box pad="medium">
                <Box>
                  <Box direction={appDirection} justify="start" align="left">
                    <Box style={{ flexBasis: '60%' }}>
                      <ol style={{ marginBottom: '0.2rem' }}>
                        <li type="1" value="6">
                          <span>
                            {
                              '利用開始日に初期登録されたユーザー様はFORKLOREからの招待メールを受信します。'
                            }
                          </span>
                        </li>
                        <li type="1">
                          <span>{'招待メールの案内に従ってログインしてください。'}</span>
                        </li>
                        <li type="1">
                          <span>{'FORKLOREトップ画面が開きます。'}</span>
                        </li>
                      </ol>
                    </Box>
                    <Box style={{ flexBasis: '40%' }}>
                      <StaticImage
                        objectFit="contain"
                        src="../../images/howtoApply/step5-flow.png"
                        formats={['auto', 'webp', 'avif']}
                        alt={'初回ログイン'}
                        imgClassName="ofi"
                        imgStyle={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default FlowDetailStep5;
