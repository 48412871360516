import * as React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import FlowDetailTitle from './flowDetailTitle';
import { StaticImage } from 'gatsby-plugin-image';

const FlowDetailStep2 = () => {
  let appDirection = 'column';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
        } else {
          appDirection = 'column';
        }
        return (
          <Box style={{ marginBottom: '1.5rem' }}>
            <Box border="all">
              <FlowDetailTitle step="step2" label="ドラレコのご注文" />
              <Box pad="medium">
                <Box style={{ marginBottom: '1rem' }}>
                  <ol style={{ marginBottom: '0.5rem' }}>
                    <li type="1" value="2">
                      {
                        'お問い合わせいただいたご連絡先へ、最寄りのトヨタL&F販売店よりご連絡をさせていただきますので、'
                      }
                      <a href="https://www.yupiteru.co.jp/products/biz_fdr/fdr-820/">
                        {'ユピテル製ドライブレコーダー(FDR-820)'}
                      </a>
                      {'をご注文ください。'}
                    </li>
                  </ol>
                  <Box>
                    <span style={{ fontSize: '0.9rem' }}>
                      ※FDR-820以外のドラレコではドラレコConnectをご利用できませんのでご注意ください。
                    </span>
                  </Box>
                </Box>
                <Box direction={appDirection} justify="center" align="start">
                  <Box basis="50%" justify="center" align="center">
                    <StaticImage
                      objectFit="contain"
                      src="../../images/howtoApply/content-step2-left.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'ドラレコ'}
                      height={300}
                      imgClassName="ofi"
                    />
                    <Box style={{ marginTop: '1.5rem' }}>
                      <StaticImage
                        objectFit="contain"
                        src="../../images/howtoApply/content-step2-left-table.png"
                        formats={['auto', 'webp', 'avif']}
                        alt={'プラン'}
                        height={300}
                        imgClassName="ofi"
                      />
                    </Box>
                    <Box
                      align="start"
                      style={{
                        marginTop: '0.5rem',
                        marginBottom: '1rem',
                        padding: '0px 24px 24px 30px',
                      }}
                    >
                      <Box>
                        <Box direction="row" style={{ marginBottom: '0.2rem' }}>
                          <span style={{ fontSize: '0.8rem' }}>{'*1　'}</span>
                          <Box>
                            <span style={{ fontSize: '0.8rem' }}>
                              SDカードからの動画呼び出し機能があるため、Starterプランでも常時動画を記録します。
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box basis="50%" justify="center" align="center">
                    <StaticImage
                      objectFit="contain"
                      src="../../images/howtoApply/content-step2-right.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'必須アイテム'}
                      height={300}
                      imgClassName="ofi"
                    />
                    <Box style={{ marginTop: '0.3rem' }}>
                      <span style={{ fontSize: '0.8rem' }}>
                        {'◎：必要、〇：車両位置検知利用の場合は必要'}
                      </span>
                      <span style={{ fontSize: '0.8rem' }}>{'（　）はどちらか必須'}</span>
                    </Box>
                    <Box style={{ marginTop: '0.3rem' }}>
                      <span style={{ fontSize: '0.8rem' }}>{'*1 時刻同期したい場合推奨'}</span>
                      <span style={{ fontSize: '0.8rem' }}>
                        {'*2 オペレーター判断で録画したい場合に使用'}
                      </span>
                    </Box>
                    <Box style={{ marginTop: '1rem' }}>
                      <StaticImage
                        objectFit="contain"
                        src="../../images/howtoApply/content-step2-right-camera.png"
                        formats={['auto', 'webp', 'avif']}
                        alt={'カメラ解像度'}
                        height={300}
                        imgClassName="ofi"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default FlowDetailStep2;
