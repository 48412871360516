import * as React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import FlowDetailTitle from './flowDetailTitle';

const FlowDetailStep3 = () => {
  let appDirection = 'column';
  let displayBox = 'none';
  let displayBoxSmall = null;

  const locations = [
    '会社名/所属',
    '車両情報',
    'ユーザー情報',
    'Wi-Fi環境,（Wi-Fiの場合のみ）',
    '運転者',
  ];
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          displayBox = null;
          displayBoxSmall = 'none';
        } else {
          appDirection = 'column';
          displayBox = 'none';
          displayBoxSmall = null;
        }
        return (
          <Box style={{ marginBottom: '1.5rem' }}>
            <Box border="all">
              <FlowDetailTitle step="step3" label="ご利用申し込み" />
              <Box pad="medium">
                <Box>
                  <ol style={{ marginBottom: '0.2rem' }}>
                    <li type="1" value="3">
                      {
                        'FORKLORE事務局より必要書類（①利用申込書、②初期情報登録シート、③FORKLORE利用規約）をe-mailにてご案内いたします。ご記入・ご署名のうえe-mail添付にてご返送ください。'
                      }
                    </li>
                  </ol>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default FlowDetailStep3;
